import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OidcStorageService } from '@app/services/oidc-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TenantInterceptor implements HttpInterceptor {
  constructor(private oidcStorageService: OidcStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const url = new URL(window.location.href);
        if (url.searchParams.has('revoke-token')) {
          this.oidcStorageService.clear();
          const url = new URL(window.location.href);
          url.searchParams.delete('revoke-token');
          window.location.href = url.toString();
        } else {
          if (err.status === 400) {
            const { error } = err.error;
            if (error?.code === 69 && error?.appUrl) {
              const url = new URL(error.appUrl);
              url.searchParams.append('revoke-token', '1');
              setTimeout(() => {
                window.location.href = url.toString();
              }, 100);
            }
          }
        }
        return throwError(() => err);
      })
    );
  }
}
