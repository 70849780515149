import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { OidcAuthModule } from '@main-application/oidc-auth/oidc-auth.module';
import { TenantInterceptor } from '@shared/interceptors/tenant.interceptor';
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';
import { ModalsModule } from '@ui-components/modals/modals.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { environment } from '../environments/environment';
import { EnvironmentType } from '../environments/environment-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    OidcAuthModule,

    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,

    MatSnackBarModule, //Can't be removed because of using SnackService in TokenInterceptor (but will be nice to find way to remove it)

    StoreModule.forRoot({}), //Can't be removed because of next problem: (but will be nice to find way to remove it)
    //If store is initialized for root anywhere except AppModule it will not work
    //It's well known bug: https://github.com/ngrx/platform/issues/3700
    //Workaround is to initialized it here, but everything else should be done in correct modules
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.type === EnvironmentType.Dev,
    }),

    MatDialogModule,
    ModalsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    //Can't be removed as HTTP_INTERCEPTORS is global and registered in AppModule (but will be nice to find way to remove it)
    //Some more details: https://dev.to/devakone/angular-when-httpinterceptor-doesn-t-work-with-lazy-loaded-modules-3ipg
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
